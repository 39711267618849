<template>
  <register-container fluid fullHeight>
    <div class="welcome-wrapper d-flex align-center justify-center">
      <div class="welcome-content px-3 pb-8 pb-md-0">
        <v-row class="mt-0 mt-md-10">
          <v-col cols="12" md="5" v-if="this.$vuetify.breakpoint.mdAndUp">
            <v-img :src="image" class="side-image" position="right center" />
          </v-col>
          <v-col cols="12" md="7">
            <div class="logo-header-column d-flex justify-end" v-if="$vuetify.breakpoint.mdAndUp">
              <logo-image :prefix="$route.params.id" />
            </div>
            <div class="px-0 px-sm-4 px-lg-16">
              <h1 class="text-xs-h3 text-sm-h3 font-weight-regular mb-8 mt-4 mt-md-0 mb-sm-8">
                {{ $t('registration.registerNewReturning.book') }}
              </h1>
              <div class="mb-6">
                {{ para1 }}
              </div>

              <div class="mb-6">{{ $t('registration.registerNewReturning.instructions5') }}</div>
              <div
                class="d-flex justify-space-around pt-6"
                :class="this.$vuetify.breakpoint.smAndUp ? 'flex-row' : 'flex-column'"
              >
                <div>
                  <hover-button
                    :classes="'ma-1'"
                    :block="this.$vuetify.breakpoint.xsOnly"
                    :customWidth="this.$vuetify.breakpoint.smAndUp ? '215px' : null"
                    :disabled="loading"
                    @click="goNext(false)"
                    :selected="buttonActive"
                    >{{ $t('registration.registerNewReturning.start') }}</hover-button
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </register-container>
</template>
<style lang="scss">
.choice-container {
  width: 100%;
}
.image-small {
  max-width: 250px;
}
.side-image {
  height: 100%;
}
.logo-header-column {
  margin-bottom: 16px;
}
@media all and (min-width: 600px) {
  .welcome-wrapper {
    height: 90%;
  }
}

@media all and (min-width: 1264px) {
  .welcome-content {
    width: 80%;
    min-width: 1200px;
    max-width: 1600px;
  }
}
</style>
<script>
/* eslint-disable global-require */
import HoverButton from '../components/HoverButton.vue';
import LogoImage from '../components/LogoImage.vue';
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  name: 'RegisterNewReturningTwo',
  components: { HoverButton, LogoImage, RegisterContainer },
  data() {
    return {
      buttonActive: false,
      image: require('../images/smiling_doctor.png'),
      loading: false,
      logo: require('../images/SentryHealth-LogoV2.png'),
      para1: this.$t('registration.registerNewReturning.para1'),
    };
  },
  methods: {
    async goNext(isReturningPatient) {
      await this.$store.commit('setRegistration', { isReturningPatient });
      this.$emit('next');
    },
  },
  computed: {
    location() {
      return this.$store.state.registration.location;
    },
  },
};
</script>
